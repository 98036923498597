@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');


:root {
    /* Fuentes principales */
    --fuente1: 'Poppins', sans-serif;
    --fuente2: 'Satisfy', cursive;

    /* Bordes */
    --r: 1px;
    --r0: 4px;
    --r1: 8px;
    --r2: 12px;
    --r3: 16px;

    /* Tamaños de texto */
    --h1: 2em;
    --h2: 2.25em;
    --h3: 1.875em;
    --h4: 1.5em;
    --h5: 1.25rem;
    --h6: 1rem;
    --lt: 1.25rem;
    --p: 1em;
    --s: 0.875rem;
    --t: 0.75rem;

    /* Paleta de colores */
    --mine-shaft-50: #f6f6f6;
    --mine-shaft-100: #e7e7e7;
    --mine-shaft-200: #d1d1d1;
    --mine-shaft-300: #b0b0b0;
    --mine-shaft-400: #888888;
    --mine-shaft-500: #6d6d6d;
    --mine-shaft-600: #5d5d5d;
    --mine-shaft-700: #4f4f4f;
    --mine-shaft-800: #454545;
    --mine-shaft-900: #333333; /* Principal */
    --mine-shaft-950: #262626;

    --azure-radiance-50: #edfaff;
    --azure-radiance-100: #d6f3ff;
    --azure-radiance-200: #b5ebff;
    --azure-radiance-300: #83e1ff;
    --azure-radiance-400: #48ceff;
    --azure-radiance-500: #1eb0ff;
    --azure-radiance-600: #0693ff;
    --azure-radiance-700: #007fff; /* Principal */
    --azure-radiance-800: #0861c5;
    --azure-radiance-900: #0d549b;
    --azure-radiance-950: rgba(14, 51, 93, 0.5);

    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 0;
    --bs-card-border-color: rgba(0, 0, 0, 0.125);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --bs-card-inner-border-radius: 0.75rem;
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: #fff;
    --bs-card-cap-color: ;
    --bs-card-height: auto;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;


}

body {
    margin: 0;
    padding: 0;
    font-family: var(--fuente1); /* Fuente predeterminada para el cuerpo del sitio */
    background-color: var(--mine-shaft-50); /* Color de fondo predeterminado */
    color: var(--mine-shaft-900); /* Color de texto predeterminado */
}
